import './index.scss';

$(document).ready(() => {
  $('.form-product-search .input-theme').on('change', function() {
    addTheme(parseInt($(this).val()), $(this).find('option:selected').text());

    triggerQuery();
    $('#back-btn-form').show();  
    hideCategory();
  })

  $("#mobile-btn-form").on('click', function() {
    $(this).hide();
    $('.form-generator').show();
  })

  $('.form-product-search .input-gender').on('change', function() {
    if ($('.form-product-search').data('idMenCategory') == $(this).find('option:selected').val() ||
        $('.form-product-search').data('idChildCategory') == $(this).find('option:selected').val()
        ) {
      $('.second-part').hide();
    } else {
      $('.second-part').show();
    }

    triggerQuery();
    $('#back-btn-form').show();  
    hideCategory();  
  });

  $('.form-product-search .input-age').on('change', function() {
    triggerQuery();
    $('#back-btn-form').show();  
    hideCategory();
  });

  $(document).on('click', '.selected-item', function() {
    $(this).remove();

    refreshTheme();

    triggerQuery();
  });

  $(document).on('keyup', '.input-price', function() {
    triggerQuery();
  });
    
  $(document).on('click', '#back-btn-form', function() {
    $(this).hide();
    backToPrevious();
  });

  function addTheme(category_id, title) {
    var item = $("<span/>");
    var selectedChoices = $('.selected-choices');
    var items = selectedChoices.find('.selected-item');

    if (0 == category_id) {
      return;
    }

    item.addClass('selected-item');
    item.attr('data-category-id', category_id);
    item.text(title);

    selectedChoices.empty();

    items.each(function(index, value) {
      selectedChoices.append(value);

      if (index != (items.length - 1)) {
        selectedChoices.append(', ');
      }
    });

    if (items.length > 0) {
      selectedChoices.append(' et ');
    }

    selectedChoices.append(item);
  }

  function refreshTheme() {
    var selectedChoices = $('.selected-choices');
    var items = selectedChoices.find('.selected-item');

    selectedChoices.empty();

    items.each(function(index, value) {
      selectedChoices.append(value);

      if (items.length == 1) {
        return;
      }

      if (index != (items.length - 1)) {
        if (index == (items.length - 1)) {
          selectedChoices.append(', ');
        } else {
          selectedChoices.append(' et ');
        }
      }
    });
  }

  function triggerQuery()
  {
    var data = {
      ajax: 1,
    };

    var idGenderCategory = parseInt($('.input-gender').val());
    var idAgeCategories = parseInt($('.input-age').val());
    var idThemeCategories = [];

    $('.selected-item').each(function(index, value) {
      idThemeCategories.push(parseInt($(value).data('categoryId')));
    });

    var price = parseInt($('.input-price').val());

    if (price <= 0) {
      return false;
    }

    var path = $('.form-product-search').data('url');

    data['gender_category_id'] = idGenderCategory;
    data['age_category_id'] = idAgeCategories;
    data['categories'] = idThemeCategories;
    data['price'] = price;

    $.ajax({
      method: 'POST',
      dataType: 'json',
      url: path,
      data: data,
      success: function(result) {
        $(".form-product-result").empty();
        $('.form-product-result').append(result.html);
        $('#back-btn-form').show();  
        hideCategory();
        prestashop['select'].init('.form-product-result .custom-niceselect');
      }
    });
  }

  function hideCategory()
  {
    if ($('#_category_header').length === 0) {
        return;
    }
      
    $('.block-category').empty();
    $('#products').empty();      
  }

  function backToPrevious()
  {
    $('.form-product-result').empty();
    if ($('#category').length === 1) {
        location.reload();
    }  
  }
});
